import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/opt/build/repo/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"600\"],\"variable\":\"--font-openSans\",\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["AOSComponent"] */ "/opt/build/repo/src/components/AOSComponent/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GtagLink"] */ "/opt/build/repo/src/components/fixedWhatsapp/GtagLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Menu"] */ "/opt/build/repo/src/components/header/Menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactThemeToggleButton"] */ "/opt/build/repo/src/components/reactThemeToggle/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/components/header.scss");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/components/footer.scss");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/components/heading.scss");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/components/fixedWhatsapp.scss");
